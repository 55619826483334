import React, { useRef } from "react";
import Slider from "react-slick";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './Carousel.css'; 

import Schedule from '../../assets/images/Schedule.png';
import Join from '../../assets/images/Join.png';
import CreatePatient from '../../assets/images/Create_Patient.png';

const StepsCarousel = () => {
  let sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div className="slider-container">
      <Slider ref={slider => (sliderRef = slider)} {...settings}>
        <Container className="d-flex justify-content-center w-100">
          <Row className="w-100">
            <Col md={6} className="d-flex flex-column justify-content-center align-items-start">
              <div className="steps-number">01</div>
              <div className="steps-title">Join for Free</div>
              <div className="steps-content pt-4">Creating your account takes less than 5 minutes.</div>
            </Col>
            <Col md={6} className="d-flex justify-content-center align-items-center">
              <Image className="steps-image" src={Join} />
            </Col>
          </Row>
        </Container>
        <Container className="d-flex justify-content-center w-100">
          <Row className="w-100">
            <Col md={6} className="h-100 d-flex flex-column justify-content-center align-items-start">
              <div className="steps-number">02</div>
              <div className="steps-title">Enter Patient Info</div>
              <div className="steps-content pt-4">We get it—retyping addresses is a drag. That's why we added an autocomplete feature, so your fingers can take a break!</div>
            </Col>
            <Col md={6} className="d-flex justify-content-center align-items-center h-100">
              <Image className="steps-image" src={CreatePatient} />
            </Col>
          </Row>
        </Container>
        <Container className="d-flex justify-content-center w-100">
          <Row className="w-100">
            <Col md={6} className="d-flex flex-column justify-content-center align-items-start">
              <div className="steps-number">03</div>
              <div className="steps-title">Start Scheduling</div>
              <div className="steps-content pt-4">Drag and drop your patients into the calendar and test the drive time between each PT. It only takes 2 clicks!</div>
            </Col>
            <Col md={6} className="d-flex justify-content-center align-items-center">
              <Image className="steps-image" src={Schedule} />
            </Col>
          </Row>
        </Container>
      </Slider>
    </div>
  );
};

export default StepsCarousel;
